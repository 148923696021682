// GENERIC UTILITIES
@if type-of($briks-utilities) == map {
    @each $property, $data in $briks-utilities {
        @if map-get($data, enabled) {
            @if type-of(map-get($data, names-and-values)) == map {
                @each $name, $value in map-get($data, names-and-values) {
                    .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name} {
                        #{$property}: $value !important;
                    }

                    // Pseudo classes
                    @if (type-of(map-get($data, pseudo-classes)) == list or type-of(map-get($data, pseudo-classes)) == string) and length(map-get($data, pseudo-classes)) > 0 {
                        @each $pseudo_class, $enabled in map-get($data, pseudo-classes) {
                            .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class} {
                                &:#{$pseudo_class} {
                                    #{$property}: $value !important;
                                }
                            }
                        }
                    }
                }
            }
            @if type-of(map-get($data, additional-names-and-values)) == map {
                @each $name, $value in map-get($data, additional-names-and-values) {
                    .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name} {
                        #{$property}: $value !important;
                    }
                    // Pseudo classes
                    @if (type-of(map-get($data, pseudo-classes)) == list or type-of(map-get($data, pseudo-classes)) == string) and length(map-get($data, pseudo-classes)) > 0 {
                        @each $pseudo_class, $enabled in map-get($data, pseudo-classes) {
                            .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class}:#{$pseudo_class} {
                                #{$property}: $value !important;
                            }
                        }
                    }
                }
            }
            @if map-get($data, responsive) {
                @each $screen-size, $v in $briks-screen-sizes {
                    @include my-screen-size($screen-size) {
                        @if type-of(map-get($data, names-and-values)) == map {
                            @each $name, $value in map-get($data, names-and-values) {
                                .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}#{$briks-responsive-prefix}#{$screen-size},
                                [#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}*="#{$screen-size}"] {
                                    #{$property}: $value !important;
                                }

                                // Pseudo classes
                                @if (type-of(map-get($data, pseudo-classes)) == list or type-of(map-get($data, pseudo-classes)) == string) and length(map-get($data, pseudo-classes)) > 0 {
                                    @each $pseudo_class, $enabled in map-get($data, pseudo-classes) {
                                        .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class}#{$briks-responsive-prefix}#{$screen-size}:#{$pseudo_class},
                                        [#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class}*="#{$screen-size}"]:#{$pseudo_class} {
                                            #{$property}: $value !important;
                                        }
                                    }
                                }
                            }
                        }
                        @if type-of(map-get($data, additional-names-and-values)) == map {
                            @each $name, $value in map-get($data, additional-names-and-values) {
                                .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}#{$briks-responsive-prefix}#{$screen-size},
                                [#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}*="#{$screen-size}"] {
                                    #{$property}: $value !important;
                                }

                                // Pseudo classes
                                @if (type-of(map-get($data, pseudo-classes)) == list or type-of(map-get($data, pseudo-classes)) == string) and length(map-get($data, pseudo-classes)) > 0 {
                                    @each $pseudo_class, $enabled in map-get($data, pseudo-classes) {
                                        .#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class}#{$briks-responsive-prefix}#{$screen-size}:#{$pseudo_class},
                                        [#{$briks-utilities-prefix}#{map-get($data, prefix)}#{$name}\:#{$pseudo_class}*="#{$screen-size}"]:#{$pseudo_class} {
                                            #{$property}: $value !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
